import { default as indexne6wGiB9UhMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/[slug]/index.vue?macro=true";
import { default as _91id_93oiKF0ftb58Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/[slug]/posts/[id].vue?macro=true";
import { default as _2257prgkIDCsp1Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/2257.vue?macro=true";
import { default as indexLPB7scxsiOMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/account/holding/index.vue?macro=true";
import { default as analytics_45and_45reportsq8rIgHbq0bMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/analytics-and-reports.vue?macro=true";
import { default as anti_45spam_45policyvzgMjIom42Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/anti-spam-policy.vue?macro=true";
import { default as billing_45supportnQ6qjJdmikMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/billing-support.vue?macro=true";
import { default as calendar77vFLsgwXhMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/calendar.vue?macro=true";
import { default as california_45privacy_45noticeHBsefHmOP6Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/california-privacy-notice.vue?macro=true";
import { default as community_45standardscGAmijbStJMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/community-standards.vue?macro=true";
import { default as content_45removalCtyqcXZpSHMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/content-removal.vue?macro=true";
import { default as cookie_45noticerEaMQTezEuMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/cookie-notice.vue?macro=true";
import { default as dmca3L53kBFwy5Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/dmca.vue?macro=true";
import { default as email_45confirmedDwA2fAH8xrMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/email-confirmed.vue?macro=true";
import { default as favoritesGxpumC8COwMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/favorites.vue?macro=true";
import { default as forgot_45passwordZETPSlGNPBMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/forgot-password.vue?macro=true";
import { default as general_45terms_45of_45serviceOXbicT8RMoMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/general-terms-of-service.vue?macro=true";
import { default as healthjSWxK9xB7IMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/health.vue?macro=true";
import { default as indexUN7I90mZ2yMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/index.vue?macro=true";
import { default as joinr1ErDuYHwIMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/join.vue?macro=true";
import { default as creatorsVGMbmc9zekMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/l/creators.vue?macro=true";
import { default as loginvf4YRpxwHfMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/login.vue?macro=true";
import { default as maintenanceA17rgOE43BMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/maintenance.vue?macro=true";
import { default as mediaG3zji39WpKMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/media.vue?macro=true";
import { default as _91_91username_93_93aTmwr5FnxnMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/messages/[[username]].vue?macro=true";
import { default as notificationsIBQnhEgkMwMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/notifications.vue?macro=true";
import { default as callbackTAWEAB46gRMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/oauth/[provider]/callback.vue?macro=true";
import { default as callbackNiblJBcm30Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/oauth/discord/callback.vue?macro=true";
import { default as privacy8Vs9Wolp5gMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/privacy.vue?macro=true";
import { default as purchasesRxwyrrFLWSMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/purchases.vue?macro=true";
import { default as reset_45password4SqPPUMxjaMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/reset-password.vue?macro=true";
import { default as settings2ycmptARpRMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/settings.vue?macro=true";
import { default as statementskHI0PlPVc4Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/statements.vue?macro=true";
import { default as terms_45for_45creatorsMZk69bBjz9Meta } from "/home/runner/work/favoritely/favoritely/frontend/pages/terms-for-creators.vue?macro=true";
import { default as terms_45for_45userskjsEeY0A1tMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/terms-for-users.vue?macro=true";
import { default as index86jlla9B6VMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/test/index.vue?macro=true";
import { default as walletELc6wxvkaMMeta } from "/home/runner/work/favoritely/favoritely/frontend/pages/wallet.vue?macro=true";
export default [
  {
    name: "profile",
    path: "/:slug()",
    meta: indexne6wGiB9UhMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-posts-id",
    path: "/:slug()/posts/:id()",
    meta: _91id_93oiKF0ftb58Meta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/[slug]/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: "2257",
    path: "/2257",
    meta: _2257prgkIDCsp1Meta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/2257.vue").then(m => m.default || m)
  },
  {
    name: "account-holding",
    path: "/account/holding",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/account/holding/index.vue").then(m => m.default || m)
  },
  {
    name: "analytics-and-reports",
    path: "/analytics-and-reports/:section?/:filter?/:subfilter?",
    alias: ["/reports/:section?/:filter?/:subfilter?","/analytics/:section?/:filter?/:subfilter?"],
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/analytics-and-reports.vue").then(m => m.default || m)
  },
  {
    name: "anti-spam-policy",
    path: "/anti-spam-policy",
    meta: anti_45spam_45policyvzgMjIom42Meta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/anti-spam-policy.vue").then(m => m.default || m)
  },
  {
    name: "billing-support",
    path: "/billing-support",
    meta: billing_45supportnQ6qjJdmikMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/billing-support.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "california-privacy-notice",
    path: "/california-privacy-notice",
    meta: california_45privacy_45noticeHBsefHmOP6Meta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/california-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: "community-standards",
    path: "/community-standards",
    meta: community_45standardscGAmijbStJMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/community-standards.vue").then(m => m.default || m)
  },
  {
    name: "content-removal",
    path: "/content-removal",
    meta: content_45removalCtyqcXZpSHMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/content-removal.vue").then(m => m.default || m)
  },
  {
    name: "cookie-notice",
    path: "/cookie-notice",
    meta: cookie_45noticerEaMQTezEuMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/cookie-notice.vue").then(m => m.default || m)
  },
  {
    name: "dmca",
    path: "/dmca",
    meta: dmca3L53kBFwy5Meta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/dmca.vue").then(m => m.default || m)
  },
  {
    name: "email-confirmed",
    path: "/email-confirmed",
    meta: email_45confirmedDwA2fAH8xrMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/email-confirmed.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    alias: ["/favorites/:slug?"],
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordZETPSlGNPBMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "general-terms-of-service",
    path: "/general-terms-of-service",
    meta: general_45terms_45of_45serviceOXbicT8RMoMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/general-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "health",
    path: "/health",
    meta: healthjSWxK9xB7IMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/health.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexUN7I90mZ2yMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "join",
    path: "/join",
    meta: joinr1ErDuYHwIMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/join.vue").then(m => m.default || m)
  },
  {
    name: "l-creators",
    path: "/l/creators",
    meta: creatorsVGMbmc9zekMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/l/creators.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceA17rgOE43BMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/media.vue").then(m => m.default || m)
  },
  {
    name: "messages-username",
    path: "/messages/:username?",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/messages/[[username]].vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "oauth-provider-callback",
    path: "/oauth/:provider()/callback",
    meta: callbackTAWEAB46gRMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/oauth/[provider]/callback.vue").then(m => m.default || m)
  },
  {
    name: "oauth-discord-callback",
    path: "/oauth/discord/callback",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/oauth/discord/callback.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy8Vs9Wolp5gMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "purchases",
    path: "/purchases",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/purchases.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4SqPPUMxjaMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings/:section?",
    meta: settings2ycmptARpRMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "statements",
    path: "/statements",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/statements.vue").then(m => m.default || m)
  },
  {
    name: "terms-for-creators",
    path: "/terms-for-creators",
    meta: terms_45for_45creatorsMZk69bBjz9Meta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/terms-for-creators.vue").then(m => m.default || m)
  },
  {
    name: "terms-for-users",
    path: "/terms-for-users",
    meta: terms_45for_45userskjsEeY0A1tMeta || {},
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/terms-for-users.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet",
    path: "/wallet",
    alias: ["/wallet/:slug?"],
    component: () => import("/home/runner/work/favoritely/favoritely/frontend/pages/wallet.vue").then(m => m.default || m)
  }
]