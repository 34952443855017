export default defineNuxtRouteMiddleware((to) => {
  const runtimeConfig = useRuntimeConfig()

  // NOTE: Redirects all sitemaps to the S3 bucket
  // http://favoritely.com/sitemaps/sitemap_index.xml => https://favoritely-sitemaps.s3.us-west-1.amazonaws.com/public/sitemaps/sitemap_index.xml

  if (to.fullPath.includes("sitemaps")) {
    const match = to.fullPath.match(/sitemaps\/(.+)/)
    if (match && match[1]) {
      const sitemap = match[1]
      const redirectUrl = `https://favoritely-sitemaps.s3.us-west-1.amazonaws.com/public/sitemaps/${sitemap}`
      return navigateTo(redirectUrl, { external: true, redirectCode: 301 })
    }
  }
})
