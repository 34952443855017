import { defineNuxtPlugin, useRuntimeConfig } from "#app";
import {
  fpjsPlugin,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-vue-v3";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  // TODO: Un-hardcode the endpoint and scriptUrlPattern. Use the ENV variables instead.
  nuxtApp.vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey: "30vbXa4TkwjigUBBJ54s",
      endpoint: [
        "https://favoritely.com/ONsOH30tKmFftdD4/AgfvAUdgS7cTKJng",
        FingerprintJSPro.defaultEndpoint
      ],
      scriptUrlPattern: [
        "https://favoritely.com/ONsOH30tKmFftdD4/c8EgjRGzE68TGyUN?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
        FingerprintJSPro.defaultScriptUrlPattern
      ],
    },
  });
});
